<template>
    <div class="TyC">
        <div class="header">
            <p style="text-align: -webkit-left; margin-bottom:0px; color:#495057; margin-right:1rem;align-self: center; cursor: pointer;" v-on:click="closeOverlay()">&#11013;</p>
            <h2 class="title">Como usamos tu información</h2>
        </div>
        <div class=buttonsContainer>
            <div class="boton" :class="{ selected: isActive == 0 }" v-on:click="selectTab(0)"> Cómo funciona </div>
            <div class="boton" :class="{ selected: isActive == 1 }" v-on:click="selectTab(1)"> Seguridad y privacidad</div>
        </div>
        <div class="content">
            <div v-if="isActive==0" class="works">
                <div class="steps">
                    <div class="step-icon">
                        <i class="material-icons">search</i>
                    </div>
                    <div class="step-content">
                        <p><strong>A través de la plataforma dispuesta por uno de nuestros prestadores de servicios,</strong> entramos a tu portal bancario, con el usuario y clave que tu ingreses en este link.</p>
                    </div>
                </div>
                <div class=" steps pair-step">
                    <div class="step-icon">
                        <i class="material-icons">cloud_off</i>
                    </div>
                    <div class="step-content">
                        <p>Solo funcionamos como una pasarela de información. <strong>No almacenamos tus datos ni información sensible</strong></p> 
                    </div>
                </div>
                <div class="steps">
                    <div class="step-icon">
                        <i class="material-icons">logout</i>
                    </div>
                    <div class="step-content">
                        <p><strong>No tocamos tu dinero.</strong></p>                       
                    </div>
                </div>
                <div class="steps pair-step">
                    <div class="step-icon">
                        <i class="material-icons">star</i>
                    </div>
                    <div class="step-content">
                        <p> Para que la información sea lo mas completa posible, <strong>te sugerimos seleccionar la cuenta donde recibes tus ingresos o salario</strong></p>
                    </div>
                </div>
            </div>
            <div class="security" v-if="isActive==1">
                <div class="header-security">
                    <h3> No te preocupes, Wift es:</h3>
                </div>
                <div class="steps pair-step">
                    <div class="step-icon">
                        <i class="material-icons">lock</i>
                    </div>
                    <div class="step-content">
                        <h4> Seguro:</h4>
                        <p> Tu información viaja siempre de manera encriptada de un extremo a otro (encriptación AES-256)</p>
                    </div>
                </div>
                <div class="steps">
                    <div class="step-icon">
                        <i class="material-icons">visibility_off</i>
                    </div>
                    <div class="step-content">
                        <h4> Privado:</h4>
                        <p> No guardamos tus contraseñas, ni almacenamos información sensible</p>
                    </div>
                </div>
                <div class="steps pair-step">
                    <div class="step-icon">
                        <i class="material-icons">verified_user</i>
                    </div>
                    <div class="step-content">
                        <h4> Confiable:</h4>
                        <p>Nos preocupamos de cumplir con nuestra Ley de Protección de Datos Personales.</p>
                    </div>
                </div>
                <div class="steps">
                    <div class="step-icon">
                        <i class="material-icons">share</i>
                    </div>
                    <div class="step-content">
                        <h4> Conectado:</h4>
                        <p> Integrado de manera segura con los principales bancos de Chile</p>                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
          return{
            isActive:0
          }
        },
        created(){
            this.$vuetify.theme.light = true
        },
        computed:{
            
        },
        methods:{
            closeOverlay(){
                console.log("ENTREEEE")
                this.$emit("close")
            },
            selectTab(n){
                this.isActive = n
            }
        }
    }
</script>

<style lang="scss" scoped>

.title{
    color:#003242;
    font-size: 16px;
    font-weight: 700;
    font-family: "Ubuntu" !important;

}
.TyC{
    margin-top:2rem;
}
.header{
    display: flex;
    margin-bottom: 1rem;
}
.buttonsContainer{
    font-size: 18px;
    margin-bottom: 8px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 15%);
    background-color: #fff;
    color:#003242;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Ubuntu" !important;
    .boton{
        width: 50%;
        text-align: center;
        padding: 6px 4px;
        cursor: pointer;
        color: #1b1b1b;
        margin-bottom: 3px;
        font-weight: 400;
        font-size: 14px;
    }
    .selected{
        border-bottom: 3px solid #1b1b1b;
        margin-bottom: 0;
        color: #1b1b1b;
        font-weight: 700;
    }
}
.content{
    text-align: -webkit-auto;
    .works{
        color: #1b1b1b;
        font-family: "Ubuntu" !important;
        .pair-step{
            background-color: #e9e5e545;
        }
        .steps{
            border-top: 1rem;
            display: flex;
            .step-icon{
                align-self: center;
                width: 10%;
                text-align: center;
                margin-left: 0.5rem;
            }
            .step-content{
                margin-top: 16px;
                width: 80%;
                margin-left: 1rem;
            }
        }
    }
    .security{
        font-family: "Ubuntu" !important;
        color: #1b1b1b;
        .header-security{
            margin-top: 16px;
            margin-bottom: 16px;
        }
        .steps{
            border-top: 1rem;
            display: flex;
            .step-icon{
                align-self: center;
                width: 10%;
                text-align: center;
                margin-left: 0.5rem;
            }
            .step-content{
                margin-top: 16px;
                width: 80%;
                margin-left: 1rem;
            }
        }
        .pair-step{
            background-color: #e9e5e545;
        }  
    }
}


</style>