import axios from 'axios'
// const API_URL = 'https://wift-payments.herokuapp.com'
const API_URL = 'https://wift.credit/'


export function creditScoring (data, jwt) {
    return axios.post(`${API_URL}/credit-scoring`, data, { headers: { Authorization: `Bearer: ${jwt}`}})
  }

export function afc (data) {
  return axios.post(`${API_URL}/afc`, data)
}

export function registro_civil (data) {
  return axios.patch(`${API_URL}/registro_civil`, data)
}

export function sii (data) {
  return axios.patch(`${API_URL}/sii`, data)
}

export function deuda (data) {
  return axios.patch(`${API_URL}/deuda`, data)
}

export function registro_civil_independent (data) {
  return axios.post(`${API_URL}/registro_civil_independent`, data)
}

export function bank_products (data) {
  return axios.patch(`${API_URL}/bank_products`, data)
}

export function bank_incomev2 (data) {
  return axios.patch(`${API_URL}/bank_incomev2`, data)
}