// store/index.js

import Vue from 'vue'
import Vuex from 'vuex'

// imports of AJAX functions will go here
import { authenticate, register } from '../api/auth'
import { subscriptionIntent } from '../api/fintoc'
import { documentValidation, claveUnicaValidation, creditScoringMetrik } from '../api/metriks'
import { creditScoring, afc, sii, registro_civil, deuda, registro_civil_independent, bank_products, bank_incomev2 } from '../api/floid'
import { isValidJwt, EventBus } from '../utils'
import * as notification from '@/store/modules/notification.js'

Vue.use(Vuex)

const state = {
  // single source of data
  surveys: [],
  currentSurvey: {},
  user: {},
  jwt: '',
  widgetToken: '',
  scoring: false,
  correct:false,
  afc: false,
  sii: false,
  deuda: false,
  registroCivil: false,
  name: '',
  account: '',
  incomev2: false,
  documentValidation: '',
  claveUnicaValidation:'',
  csMetriks: false
}

const actions = {
  // asynchronous operations
  login (context, userData) {
    context.commit('setUserData', { userData })
    return authenticate(userData)
      .then(response => context.commit('setJwtToken', { jwt: response.data }))
      .catch(error => {
        console.log('Error Authenticating: ', error)
        EventBus.$emit('failedAuthentication', error)
      })
  },
  creditScoring(context, data){
    return afc(data)
      .then(
              sii(data)
              .then(
                registro_civil(data)
                .then(() => {
                  context.commit('setScoring')
                })
                .catch((error) => {
                  console.log('Error credit scoring: ', error)
                  let message = 'Error interno, intenta nuevamente'
                  if (error.response.data && error.response.data.message && error.response.data.message != "")
                  message = error.response.data.message
                  const notification = {
                    type: 'error',
                    "message": message
                  }
                  context.dispatch('notification/add', notification, { root: true })
                })
              )
              .catch((error) => {
                console.log('Error credit scoring: ', error)
                let message = 'Error interno, intenta nuevamente'
                if (error.response.data && error.response.data.message && error.response.data.message != "")
                message = error.response.data.message
                const notification = {
                  type: 'error',
                  "message": message
                }
                context.dispatch('notification/add', notification, { root: true })
              })
      )
      .catch((error) => {
        console.log('Error credit scoring: ', error)
        let message = 'Error interno, intenta nuevamente'
        if (error.response.data && error.response.data.message && error.response.data.message != "")
        message = error.response.data.message
        const notification = {
          type: 'error',
          "message": message
        }
        context.dispatch('notification/add', notification, { root: true })
      })

  },
  csDeuda(context, data){
    return deuda(data)
    .then(response=>{
      console.log(response)
      context.commit('setDeuda', response.data, context.state.jwt.token)
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      console.log('Error credit scoring: ', error.response)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.message && error.response.data.message != "")
      message = error.response.data.message
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })

  }
  ,
  csAfc(context, data){
    return afc(data)
    .then(response=>{
      console.log(response)
      context.commit('setAFC', response.data, context.state.jwt.token)
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      console.log('Error credit scoring: ', error.response)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.message && error.response.data.message != "")
      message = error.response.data.message
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },

  csSii(context, data){
    return sii(data)
    .then(response=>{
      console.log(response)
      context.commit('setSII')
    }
    )
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      let message = 'Error interno, intenta nuevamente'
      if(error.response.data && error.response.data.message && error.response.data.message != "")
      message = error.response.data.message
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },
  csRegistroCivil(context, data){
    return registro_civil(data)
    .then(response=>{
      console.log(response)
      context.commit('setRegistroCivil')
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.message && error.response.data.message != "")
      message = error.response.data.message
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },
  csBankProducts(context, data){
    return bank_products(data)
    .then(response=>{
      console.log(response)
      context.commit('setBankAccount', response.data.number)
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.message && error.response.data.message != "")
      message = error.response.data.message
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },
  csRegistroCivilIndependent(context, data){
    return registro_civil_independent(data)
    .then(response=>{
      console.log(response)
      context.commit('setRegistroCivilInd', response.data.name)
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.message && error.response.data.message != "")
      message = error.response.data.message
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },
  claveUnicaValidationMetriks(context, data){
    return claveUnicaValidation(data)
    .then(response=>{
      console.log(response)
      context.commit('setClaveUnicaValidation', response.data.mensaje)
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.mensaje && error.response.data.mensaje != "")
      message = error.response.data.mensaje
      if (message == "Login failed") message = 'Contraseña incorrecta. Recuerda que puedes bloquear tu cuenta al tercer intento'
      else if (message == "Account blocked") message = 'La cuenta se encuentra bloqueada'
      else if (message == "Invalid status value") message = 'Rut inválido'
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },
  requestCsMetriks(context, data){
    return creditScoringMetrik(data)
    .then(response=>{
      console.log(response)
      context.commit('setCsMetriks')
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      console.log(error.response)
      if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0)
      
      for (let index = 0; index < error.response.data.errors.length; index++) {
        const notification = {
          type: 'error',
          "message": error.response.data.errors[index].msg
        }
        if (notification["message"] == "Invalid value") notification["message"] = "La renta debe ser mayor que $0"
        context.dispatch('notification/add', notification, { root: true })
      }
    })
  },
  documentValidationMetriks(context, data){
    return documentValidation(data)
    .then(response=>{
      console.log(response)
      context.commit('setDocumentValidation', response.data.message)
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.mensaje && error.response.data.mensaje != "")
      message = error.response.data.mensaje
      if (message == "ID not found") message = 'Número de documento invalido'
      else if (message == "ID blocked") message = 'El número de documento se encuentra bloqueado'
      else if (message == "Invalid status value") message = 'Rut inválido'
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },
  csBankIncomev2(context, data){
    return bank_incomev2(data)
    .then(response=>{
      console.log(response)
      context.commit('setIncomev2')
    })
    .catch((error) => {
      console.log('Error credit scoring: ', error)
      let message = 'Error interno, intenta nuevamente'
      if (error.response.data && error.response.data.message && error.response.data.message != "")
      message = error.response.data.message
      const notification = {
        type: 'error',
        "message": message
      }
      context.dispatch('notification/add', notification, { root: true })
    })
  },
  creditScoring1 (context, data) {
    return creditScoring(data)
      .then(response => {
        context.commit('setScoring', response.data, context.state.jwt.token)

      })
      .catch((error,data) => {
        console.log('Error credit scoring: ', error)
        console.log(data)
        console.log(error.response.data.message)
        const notification = {
          type: 'error',
          message: error.response.data.message
        }
        context.dispatch('notification/add', notification, { root: true })
      })
  },
  register (context, userData) {
    context.commit('setUserData', { userData })
    return register(userData)
      .then(context.dispatch('login', userData))
      .catch(error => {
        console.log('Error Registering: ', error)
        EventBus.$emit('failedRegistering: ', error)
      })
  },
  submitSubscriptionIntent (context) {
    return subscriptionIntent(context.state.jwt.token)
    .then(response => context.commit('setWidgetToken', { widget_token: response.data.widget_token }))
  }
}

const mutations = {
  // isolated data mutations
  setIncomev2 (state) {
    state.incomev2 = true
  },
  setUserData (state, payload) {
    console.log('setUserData payload = ', payload)
    state.userData = payload.userData
  },
  setJwtToken (state, payload) {
    console.log('setJwtToken payload = ', payload)
    localStorage.token = payload.jwt.token
    state.jwt = payload.jwt
  },
  setScoring (state) {
    state.scoring = true
    state.correct = true
  },
  setRegistroCivilInd(state, payload){
    state.name = payload
  },
  setBankAccount(state, payload){
    state.account = payload
  },
  setClaveUnicaValidation(state, payload){
    state.claveUnicaValidation = payload
  },
  setDocumentValidation (state, payload){
    state.documentValidation = payload
  },
  setAFC (state) {
    state.afc = true
  },
  setDeuda (state) {
    state.deuda = true
  },
  setSII (state) {
    state.sii = true
  },
  setRegistroCivil (state) {
    state.registroCivil = true
  },
  setJwtLocalStorage(state){
    console.log(localStorage.token)
    console.log(state)
    if (localStorage.token) state.jwt = {token: localStorage.token}
  },
  setWidgetToken(state, payload){
    console.log('setWidgetToken payload = ', payload)
    state.widgetToken = payload.widget_token
  },
  setCsMetriks(state){
    state.csMetriks = true
  }
}

const getters = {
  // reusable data accessors
  getClaveUnica (state) {
    return state.claveUnicaValidation
  },
  getDocumentValidation (state) {
    return state.documentValidation
  },
  isAuthenticated (state) {
    return isValidJwt(state.jwt.token)
  },
  getIncomev2 (state) {
    return state.incomev2
  },
  getName (state) {
    return state.name
  },
  getAccount (state) {
    return state.account
  },
  getWidgetToken (state) {
    return state.widgetToken
  },
  getScoring (state) {
    return state.scoring
  },   
  getAFC (state) {
    return state.afc
  },
  getDeuda(state) {
    return state.deuda
  },
  getSII (state) {
    return state.sii
  },
  getRegistroCivil (state) {
    return state.registroCivil
  },
  getCorrect(state){
    return state.registroCivil && state.sii && state.afc
  },
  getCsMetriks(state){
    return state.csMetriks
  }
}

const store = new Vuex.Store({
  modules:{
    notification
  },
  state,
  actions,
  mutations,
  getters
})

export default store