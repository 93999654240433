<template>
<div style="text-align: center;margin-top: 4rem; text-align: -webkit-match-parent">
    <!-- <h1 style="font-family: 'Ubuntu';">
     <span v-show="!isAuthenticated">Ingresa y </span>Subscribe tu pago
    </h1> -->
    <div style="max-width: 400px;">
      <h2 style="font-family: 'Ubuntu'; display: inline-flex;" v-show="type != 3">
        ¡Bienvenido Wifter <p>&#128640;</p>!
      </h2>
      <h2 style="font-family: 'Ubuntu'; display: inline-flex;" v-show="type == 3">
        ¡Bienvenido  <p>&#128640;</p>!
      </h2>
      <!-- <h1 style="font-family: 'Ubuntu';">
        comunidad Wift
      </h1> -->
      <h3 style="font-family: 'Ubuntu'; font-weight: 100;">Realiza tu evaluación comercial</h3>
      <h3 style="font-family: 'Ubuntu'; font-weight: 100;">en 2 simples pasos.</h3>
      <v-btn v-show="!isAuthenticated" elevation="2" class="button" :loading="loading" style="min-width: 200px" v-on:click='navigateScoring()'>
        <span class="button-span">Comenzar!</span>
      </v-btn>
      <v-btn v-show="isAuthenticated" elevation="2" class="button" :loading="loading" style="min-width: 200px" v-on:click='openDialog()'>
        <span class="button-span">Ingresar!</span>
      </v-btn>
      <div style="margin-top: 2rem;max-width: 250px;">
        <p style="text-align: -webkit-left; margin-bottom:0px">&#128274; <span style="font-family: 'Montserrat-Light';font-size: smaller;font-weight: 900;">Este proceso es seguro</span></p>
        <p style="text-align: -webkit-left;">&#128373; <span style="font-family: 'Montserrat-Light';font-size: smaller;font-weight: 900;">Este proceso es privado</span></p>
      </div>
      
      <!-- <VuemojiPicker :picker-style="style" :is-dark="isDark" :custom-emoji="customEmoji" @emojiClick="onEmojiClick" @skinToneChange="onSkinToneChange" /> -->
      <!-- <v-app>
        <LoginDialog v-if="showDialog" @closeDialog="closeDialog"/>
      </v-app> -->
    </div>
</div>
</template>

<script>
  // import { getFintoc } from '@fintoc/fintoc-js';
  // import LoginDialog from './LoginDialog.vue'
  import router from '../router'

  export default {
    data(){
      return {
        loading: false,
        logged: false,
        showDialog: false,
        src: "",
        ready: false,
        iframeLoading: true,
        emoji: null
      }
    },
    components: {
      // LoginDialog
    },
    computed:{
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      },
      type(){
        return this.$route.query.type
      }
    },
    mounted(){
      this.src = "https://admin.floid.app/wift_chile/widget/67ddd35c15ba953ea5cfbc989045b260"
      this.ready = true

    },
    methods:{
      selectEmoji(emoji) {
        console.log(emoji)
        this.emoji = emoji
      },
      onLoad() {
        console.log('iframe loaded');
        this.iframeLoading = false;
      },
      onIframeLoad() {
        console.log('iframe loaded');
      },
      navigateScoring(){
        console.log(this.$route.query)
        let params_url = {}
        if (this.$route.query.type && this.$route.query.type == 1) router.push({ name: 'FloidView' })
        else router.push({ name: 'MetriksView', params: params_url, query: this.$route.query})
      },
      openFintoc(){
        //this.widget.open()
        this.loading = false
        //  this.$store.dispatch('submitSubscriptionIntent')
        // .then(() =>{
        //   this.options["widgetToken"] = this.$store.getters.getWidgetToken
        //   console.log(this.options)
        //   this.widget = this.fintoc.create(this.options)
        //   console.log(this.widget)
        //   setTimeout(() =>{          
        //     this.widget.open()
        //     this.loading = false },
        //     1000)
        // }
        // )
      },
      closeDialog(){
        this.showDialog = false
      },
      openDialog(){
        this.$emit('openDialog', 'login')
      }
    },
    beforeCreate(){
      // this.options = {
      //     holderType: "individual",
      //     product: "subscriptions",
      //     publicKey: "pk_live_fYYgmRVw3hhsnbmESbTVJ-qsYKsXs_Xb",
      //     webhookUrl: "https://webhook.site/5ceb7d5b-6cbc-436d-99e2-9420983d6bbb"
      //   }
      this.loading = false
      // getFintoc().then(fintoc => {
      //   this.fintoc = fintoc
      //   // this.widget = fintoc.create(this.options);
      //   this.loading = false
      // });
    },

      
  }
</script>

<style lang="scss" scoped>
.button-span{
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.button{
      background-color: #4F7181 !important;
      color: #ffffff !important;
      font-size: 20px;
      font-family: Ubuntu;
      margin-top: 1.5rem;
      border-radius: 28px;
      height: 55px !important;
      width: 250px !important;
      font-weight: bold;
}
.button2{
  color: black !important;
  margin-top: 1rem;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  box-sizing: border-box; 
  z-index: 4; 
  height: 49px; 
  width: 148px; 
  padding: 0px; 
  cursor: pointer;
  background: none rgb(39, 194, 171); 
  border: none; 
  text-align: center; 
  font-family: Montserrat-Light; 
  font-size: 18px; 
  font-weight: bold; 
  line-height: 1; 
  border-radius: 20px; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px; 
  text-decoration: none; 
  font-style: normal;
}
</style>
