<template>
  <div>
    <LoadingCs v-if="loading"/>
    <div>

      <v-stepper v-model="e1" class="stepper" style="height:600px; overflow: inherit;">
        <v-overlay
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        color="white"
        >
        <div>
          <TyC @close="closeOverlay"/>
          <!-- <v-btn
          color="orange lighten-2"
          @click="overlay = false"
          >
            Hide Overlay
          </v-btn> -->
        </div>
        </v-overlay>
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            :step="1"
            :editable="e1==2"
          >
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            :step="2"
          >
          </v-stepper-step>
<!-- 
          <v-divider></v-divider>

          <v-stepper-step step="3">
          </v-stepper-step> -->
        </v-stepper-header>
        <v-stepper-items>
          <!-- <v-stepper-content step="1">
            <div>
              <div class=rut-form>
                <h4 class=rut-text> Ingresa tu RUT </h4>
                <input type="text" class="form-control" v-model="rut" v-on:keydown.stop="blockSpecialChar" v-on:input="checkRut">
                <span class="error_form" v-if="showMessage"> Ingresa un rut valido </span>
              </div>
              <div class=rut-form style="margin-top:1rem;margin-bottom:1.5rem">
                <h4 class=rut-text> Numero de documento </h4>
                <input type="text" class="form-control" v-model="documentNumber" v-on:keydown.stop="blockSpecialCharDocument" v-on:input="checkDocumentNumber">
              </div>
              <v-card
                class="mx-auto"
                max-width="344"
                v-on:click="overlay=true"
              >
                <v-card-text style="background-color:rgb(223, 241, 248);font-family: 'Ubuntu';">
                  
                  ¿Que información voy a compartir?
                </v-card-text>
              </v-card>
              <v-btn class="submit-button" @click="nextStep()" :loading="loading_step1" :disabled="!readyStep1">
                Continuar
              </v-btn>
              <div class=rut-form>
                <div class="form-group" id="form-checkbox-field" style="display: block">
                  <small class="checkbox-label"> </small>
                </div>
              </div>
            </div>
          </v-stepper-content> -->

          <v-stepper-content step="1">
            <div class=form-box> 
              <div align=center style="height:80px"><img width=250 src='https://accounts.claveunica.gob.cl/static/img/clave_unica_color.7d8e1b56a097.svg'></div><br>
              <div class=rut-form>
                <h4 class=rut-text> Ingresa tu RUT </h4>
                <input type="text" class="form-control" v-model="rut" v-on:keydown.stop="blockSpecialChar" v-on:input="checkRut">
                <span class="error_form" v-if="showMessage"> Ingresa un rut valido </span>
              </div>
              <br />  
              <div class=rut-form>
                <h4 class=rut-text> Ingresa tu Clave Unica </h4>
                <input type="password" class="form-control password" v-model="password">
                <div class="input-info">
                  <!-- <small>No estamos guardando tu información </small>
                  <br/>
                  <small>Tu información personal es privada y está protegida, nadie podrá verlo. </small>
                  <br/> -->
                  <img src="/PB Info Color.svg" alt="image" />
                  <!-- <div align=center style="height:80px"><img width=250 :src="${publicPath}PB Info Color.png"></div><br> -->
                  <div style="margin-left:0.4rem">
                    <small>En caso de dudas contactate con ventas@wift.cl</small>
                  </div>
                    
                </div>
              </div>
              <br />
              <div class=rut-form>
                <div class="form-group" id="form-checkbox-field" style="display: block">
                  <input id="checkbox" name="checkbox" type="checkbox"  onclick="" v-model="selected"/>
                  <small class="checkbox-label"> Acepto los <a :href="`${publicPath}Políticas_de_privacidad_Infocheck.pdf`"  target="_blank" style="text-decoration: underline;" type="application/pdf">términos y condiciones</a> y la <a :href="`${publicPath}tyc_infocheck.pdf`"  target="_blank" style="text-decoration: underline;" type="application/pdf">política de privacidad</a>, por ende, brindar&eacute; acceso a Wift a su informaci&oacute;n, para que construya mi perfil de informaci&oacute;n financiera. </small>
                </div>
              </div>
              <div>
                <v-btn class="submit-button" @click="nextStep()" :disabled="!ready" :loading="loading_step1">
                  Continuar
                </v-btn>
                <!-- <v-btn class="submit-button" @click="nextStep()" :loading="loading">
                  Continuar
                </v-btn> -->
              </div>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div>
               <div class=rut-form style="margin-top:1rem;">
                <h4 class=rut-text> Ingresa tu mail </h4>
                <input type="text" class="form-control" v-model="email">
                <span class="error_form" v-if="!ready1"> Ingresa un mail válido </span>
              </div>
              <!-- <div class=rut-form>
                <h4 class=rut-text> Ingresa tu renta aproximada </h4>
                <input type="text" class="form-control" v-model="renta" v-on:keydown.stop="blockSpecialCharDocument" v-on:input="checkRent">
              </div> -->
              <div class=rut-form style="margin-top:1rem;margin-bottom:1.5rem">
                <h4 class=rut-text> Número de teléfono </h4>
                <div style="display:flex">
                  <h4 class=rut-text style="align-self: center;margin-right: 1rem; margin-top:5px"> +56 </h4>
                  <input type="text" class="form-control" v-model="phone" v-on:keydown.stop="blockSpecialCharDocument" v-on:input="checkPhone">
                </div>

              </div>
              <!-- <div class=rut-form>
                <h4 class=rut-text> Ingrese su nivel educacional </h4>
                <select class="form-control" v-model="educacion">
                  <option value="EB"> Educación Básica o Media </option>
                  <option value="TP"> Técnico Profesional </option>
                  <option value="U4">Universitaria hasta 4 años</option>
                  <option value="U5">Universitaria 5 años o más</option>
                </select>
              </div> -->

              <v-btn class="submit-button" @click="nextStep()" :loading="loading_step1" :disabled="!readyStep3">
                Continuar
              </v-btn>
              <div class=rut-form>
                <div class="form-group" id="form-checkbox-field" style="display: block">
                  <small class="checkbox-label"> </small>
                </div>
              </div>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div style="max-width: 400px; color: #003242">
              <h1 style="font-family: 'Ubuntu';margin-top: 1rem">
                Gracias por compartir tu información!
              </h1>
              <h3 style="font-family: 'Ubuntu'; margin-top: 1rem" v-show="type != 3">
                En las próximas 12 horas Wift te contactará para avanzar con el proceso de contratación.
              </h3>
              <h3 style="font-family: 'Ubuntu'; margin-top: 1rem" v-show="type == 3">
                En algunos minutos tu ejecutivo Amicar te informará sobre el resultado.
              </h3>
                <img
                :src="require('../assets/check3.png')"
                width="50%"
                color="blue"
                style="margin-top:4rem"
                />
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
  import LoadingCs from '../components/LoadingCs.vue'
  import TyC from '../components/TyC.vue'
    export default {
        name:"MetriksView",
        data(){
          return{
            publicPath: process.env.BASE_URL,
            documentNumber:"",
            rut:"",
            phone:"",
            email:"",
            password:"",
            selected: false, 
            loading:false,
            e1: 1,
            absolute: true,
            opacity: 1,
            overlay: false,
            selectedBank:"BANCO SANTANDER",
            loading_step1: false,
            data_document:{},
            data_claveUnica:{},
            renta: '',
            educacion: ''
          }
        },
        components:{
          LoadingCs,
          TyC,
        },
        created(){
          if (this.$route.query.rut) this.rut = this.format(this.$route.query.rut)
          if (this.$route.query.mail) this.email = this.$route.query.mail
          if (this.$route.query.salary) this.renta = this.formatRent(this.$route.query.salary)
          if (this.$route.query.phone) this.phone = this.$route.query.phone
        },
        computed:{
          type(){
            return this.$route.query.type
          },
          name(){
            return this.$store.getters.getName
          },
          ready(){
            return this.validRut && this.selected && this.passwordValidator
          },
          ready1(){
            return this.email == "" || this.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
          },
          readyStep1(){
            return this.validRut && this.documentNumber != null
          },
          readyStep3(){
            return  this.email && this.phone
          },
          showMessage(){
            var valor = this.clean(this.rut);
            var cuerpo = valor.slice(0, -1);
            if (cuerpo.length<3)
              return false
            return !this.validRut 
          },
          passwordValidator(){
            return this.password.length > 5 //&& /\d/.test(this.password) &&
          },
          validRut(){
            // Obtiene el valor ingresado quitando puntos y guión.
            var valor = this.clean(this.rut);
            // Divide el valor ingresado en dígito verificador y resto del RUT.
            var cuerpo = valor.slice(0, -1);
            var dv = valor.slice(-1).toUpperCase();
            // Si no cumple con el mínimo ej. (n.nnn.nnn)
            if (cuerpo.length < 7) {
              return false;
            }

            // Calcular Dígito Verificador "Método del Módulo 11"
            var suma = 0;
            var multiplo = 2;

            // Para cada dígito del Cuerpo
            for (var i = 1; i <= cuerpo.length; i++) {
              // Obtener su Producto con el Múltiplo Correspondiente
              var index = multiplo * valor.charAt(cuerpo.length - i);

              // Sumar al Contador General
              suma = suma + index;

              // Consolidar Múltiplo dentro del rango [2,7]
              if (multiplo < 7) {
                multiplo = multiplo + 1;
              } else {
                multiplo = 2;
              }
            }

            // Calcular Dígito Verificador en base al Módulo 11
            var dvEsperado = 11 - (suma % 11);

            // Casos Especiales (0 y K)
            dv = dv == "K" ? 10 : dv;
            dv = dv == 0 ? 11 : dv;

            // Validar que el Cuerpo coincide con su Dígito Verificador
            if (dvEsperado != dv) {
              return false;
            } else {
              return true;
            }
          }
        
        },
        methods:{
            
          closeOverlay(){
            this.overlay = false
          },
          nextStep(){
            this.loading_step1 = true
            // if (this.e1 == 1){
            //     this.data_document = {"rut": this.rut.replace('.', '').replace('.', ''), "serie": this.documentNumber.replace('.', '').replace('.', '')}
            //     this.$store.dispatch('documentValidationMetriks', this.data_document)
            //     .then( () =>{
            //         setTimeout(()=>{
            //             console.log("status", this.$store.getters.getDocumentValidation)
            //             if (this.$store.getters.getDocumentValidation == 'successful operation'){
            //                 this.e1 += 1
            //             }
            //             this.loading_step1 = false
            //             }, 
            //             100)
            //     }
            //     )
            // }else 
            if (this.e1 == 1){
                this.data_claveUnica = {'rut':this.rut.replace('.', '').replace('.', ''), 'claveUnica': this.password}
                this.$store.dispatch('claveUnicaValidationMetriks', this.data_claveUnica)
                .then( () =>{
                    setTimeout(()=>{
                        console.log("status", this.$store.getters.getClaveUnica)
                        if (this.$store.getters.getClaveUnica == 'Término exitoso' || this.$store.getters.getClaveUnica == 'successful operation'){
                            this.e1 += 1
                        }
                        this.loading_step1 = false
                        }, 
                        100)
                }
                )
            }
            else if (this.e1 == 2){
              this.cleanRenta(this.renta)
              const dataMtrricks = {"rut": this.clean(this.rut), email:this.email, phone:"+56"+this.phone.replace("(","").replace(")","").replace(" ",""), password: this.password }
              console.log(dataMtrricks)
              this.$store.dispatch('requestCsMetriks', dataMtrricks)
              .then( () =>{
                  setTimeout(()=>{
                      console.log("status", this.$store.getters.getCsMetriks)
                      if (this.$store.getters.getCsMetriks){
                          this.e1 += 1
                      }
                      this.loading_step1 = false
                      }, 
                      100)
                }
              )

            }
          },
          cleanRenta(renta){
            let result = renta.replace('$','')
            let nDot = Math.trunc(result.length/4)
            console.log(nDot, result)
            for (let index = 0; index < nDot; index++) {
              result = result.replace('.','');
            }
            console.log(result,parseInt(result))
            return parseInt(result)
          },
          format (rut) {
            rut = this.clean(rut)

            var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
            for (var i = 4; i < rut.length; i += 3) {
              result = rut.slice(-3 - i, -i) + '.' + result
            }
            return result
          },
         formatDocument (document) {
            document = this.cleanDocument(document)
            var inputValue = document.replace('.', '').split("").reverse().join(""); // reverse
            var result = '';
            for (var i = 0; i < inputValue.length; i++) {
                if (i % 3 == 0 && i !=0) {
                    result += '.';
                }
                result += inputValue[i];
            }
            result = result.split("").reverse().join("")
            return result
          },
          formatRent (rent) {
            rent = this.cleanDocument(rent)
            var result = new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(rent)
            return result
          },
          formatPhone (phone) {
            phone = this.cleanDocument(phone)
            let result = ''
            let largo = 9
            if (phone.length < 9) largo = phone.length
            for (let i = 0; i < largo; i++) {
              if (i == 0) result += '('+phone[i]+') '
              else result += phone[i]
              }
            return result
          },
          checkPhone(ev) {
            console.log(ev)

            var phone = this.phone
            // Separa con un Guión el cuerpo del dígito verificador.
            phone = this.formatPhone(phone);
            console.log(phone)
            this.phone = phone
          },
          checkRent(ev) {
            console.log(ev)

            var renta = this.renta
            // Separa con un Guión el cuerpo del dígito verificador.
            renta = this.formatRent(renta);
            console.log(renta)
            this.renta = renta
          },
          clean (rut) {
            return typeof rut === 'string'
              ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
              : ''
          },
        cleanDocument (document) {
            return typeof document === 'string'
              ? document.replace(/^0+|[^0-9]+/g, '').toUpperCase()
              : ''
          },
          checkRut(ev) {
            console.log(ev)

            var rut = this.rut
            // Separa con un Guión el cuerpo del dígito verificador.
            rut = this.format(rut);
            console.log(rut)
            this.rut = rut
          },
           checkDocumentNumber(ev) {
            console.log(ev)
            var documentNumber = this.documentNumber
            // Separa con un Guión el cuerpo del dígito verificador.
            documentNumber = this.formatDocument(documentNumber);
            console.log(documentNumber)
            this.documentNumber = documentNumber
          },
          blockSpecialChar(evt) {
            var charCode = evt.which ? evt.which : evt.keyCode;
            console.log(charCode)
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
              console.log(false)
              return false
              }
            console.log(true)
            return true;
          },
        blockSpecialCharDocument(evt) {
            var charCode = evt.which ? evt.which : evt.keyCode;
            console.log(charCode)
            if (charCode > 31 && (charCode < 48 || charCode > 57) &&  charCode !== 75 &&  charCode !== 107) {
              console.log(false)
              return false
              }
            console.log(true)
            return true;
          },
          submitFloidInd(){
            this.loading = true
            const data = {rut: this.clean(this.rut), bank: this.selectedBank, password: this.password}
            this.$store.dispatch('csBankProducts', data)
            .then(()=> {
              setTimeout(()=> {
                if (this.$store.getters.getAccount != ''){
                  data["account_number"] = this.$store.getters.getAccount
                  this.$store.dispatch('csBankIncomev2', data)
                  .then(() => {
                    setTimeout(()=>{
                      if(this.$store.getters.getIncomev2) {
                        this.e1 += 1 
                        console.log("ENTRE incomeV2 CORRECTO")
                      }
                    this.loading = false
                    }, 100)
                  })
                }
                else this.loading = false
              }, 100)
            })
            console.log(data)

          },
          submitFloid(){
            this.loading = true
            const data = {mail: this.email, rut: this.clean(this.rut), password: this.password }
            this.$store.dispatch('csAfc', data)
            .then(() => {
              setTimeout(()=>{
                if (this.$store.getters.getAFC) {
                  console.log("ENTRE AFC CORRECTO")
                  this.$store.dispatch('csDeuda', data)
                  .then(() => {
                    setTimeout(() => {
                    if (this.$store.getters.getDeuda) {
                      console.log("ENTRE DEUDA CORRECTO")
                        this.$store.dispatch('csSii', data)
                        .then(()=>{
                          setTimeout(()=>{
                            if (this.$store.getters.getSII) {
                              console.log("ENTRE SII CORRECTO")
                              this.$store.dispatch('csRegistroCivil', data)
                              .then(()=>{
                                setTimeout(()=>{
                                  
                                  if(this.$store.getters.getRegistroCivil) {
                                    this.e1 += 1
                                    console.log("ENTRE Registro Civil CORRECTO")
                                    }
                                  this.loading = false
                                }, 100)
                              }) 
                            }
                            else this.loading = false
                          }, 100)
                        })
                      }
                      else this.loading = false
                    }, 100)
                  })
                }
                else this.loading = false
              }, 100)
            })
          }
        }
      }
</script>

<style lang="scss" scoped>
  .error_form{
    color: red;
    font-size: 12px;
  }
  .stepper{
    box-shadow: none !important;
    max-width: 350px;
    color:#003242 !important;
    .v-overlay{
      align-items: flex-start;
      width: 100%;
      display: block;
    }
  }
  .v-stepper__content{
    padding: 0px;
  }
  .v-stepper__header{
    box-shadow: none !important;
  }
  .v-application .primary {
    background-color: #003242 !important;
  }
  .overlay{
    background-color: #FFFFFF;
    .v-overlay__scrim{
      background-color: #FFFFFF !important;
    }
  }
  .v-overlay__scrim{
      background-color: #FFFFFF !important;
    }
  .form-box{
    max-width: 330px;
  }
  .v-label{
    font-size: 15px;
    font-weight: 600;
  }
  .color-gob{
    position: relative; 
    top:30;
    left:0;
    text-align: -webkit-center;
  }
  .rut-form{
    font-family: Montserrat-Light;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    .rut-text{
      color:#003242;
    }
    .form-group{
      color:#003242;
      font-family: Montserrat;
      font-size: 15px;
    }
    .form-control{
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      margin-top: 0.5rem;
      appearance: auto;
    }
    .password{
      font: small-caption !important;
      font-size: 18px !important;
    }
    .input-info{
      font-size: 95%;
    }
  }
  .submit-button{
      background-color: #4F7181 !important;
      color: #FFFFFF !important;
      font-size: 20px;
      font-family: Ubuntu;
      margin-top: 2rem;
      border-radius: 28px;
      height: 55px !important;
      width: 250px !important;
      font-weight: bold;
  }
  

</style>