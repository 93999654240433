<template>
  <v-row justify="center">
  
    <v-dialog
      v-model="show"
      max-width="350px"
      persistent
    >
      <v-card style="overflow-x: hidden; hidden;max-height: 270px">
        <v-card-title style="font-family:Ubuntu; display:block">
          <h5>Este proceso puede tardar</h5>
          <h5>unos segundos</h5>
        </v-card-title>
        <img style="max-width:300px" title="gif-1.gif" src="https://cdn.dribbble.com/users/1726478/screenshots/3636280/media/350a1dddbe8277ab0e6df726f890778d.gif" alt="350a1dddbe8277ab0e6df726f890778d" />

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
 export default {
   data(){
    return {
        show: true,
    }
   },
   watch:{
    },

   mounted () {
    },

    methods: {
    },
    
    beforeDestroy () {
    }
 }
</script>