<template>
  <div>
    <LoadingCs v-if="loading"/>
    <div>

      <v-stepper v-model="e1" class="stepper" style="height:600px; overflow: inherit;">
        <v-overlay
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        color="white"
        >
        <div>
          <TyC @close="closeOverlay"/>
          <!-- <v-btn
          color="orange lighten-2"
          @click="overlay = false"
          >
            Hide Overlay
          </v-btn> -->
        </div>
        </v-overlay>
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            :step="1"
            :editable="e1==2"
          >
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            :step="2"
          >
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div>
              <div class=rut-form>
                <h4 class=rut-text> Ingresa tu RUT </h4>
                <input type="text" class="form-control" v-model="rut" v-on:keydown.stop="blockSpecialChar" v-on:input="checkRut">
                <span class="error_form" v-if="showMessage"> Ingresa un rut valido </span>
              </div>
              <div class=rut-form>

                <v-radio-group v-model="workerType" row>
                  <h4 class=rut-text style="margin-bottom:0.7rem;"> ¿Que tipo de trabajador eres? </h4>
                  <v-radio
                    label="Dependiente"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="Independiente"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </div>
              <v-card
                class="mx-auto"
                max-width="344"
                v-on:click="overlay=true"
              >
                <v-card-text style="background-color:rgb(223, 241, 248);font-family: 'Ubuntu';">
                  
                  ¿Que información voy a compartir?
                </v-card-text>
              </v-card>
              <v-btn class="submit-button" @click="nextStep()" :loading="loading_step1" :disabled="!readyStep1">
                Continuar
              </v-btn>
              <div class=rut-form>
                <div class="form-group" id="form-checkbox-field" style="display: block">
                  <small class="checkbox-label"> </small>
                </div>
              </div>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2" v-if="workerType == 0">
            <div class=form-box> 
              <div class="color-gob"><img width=100% src='https://admin.floid.app/widget/assets/img/barra_claveunica.png'></div><br> 
              <div align=center><img width=250 src='https://admin.floid.app/widget/assets/img/claveunica.png'></div><br>
              <div class=rut-form>
                <h4 class=rut-text> Ingresa tu RUT </h4>
                <input type="text" class="form-control" v-model="rut" v-on:keydown.stop="blockSpecialChar" v-on:input="checkRut">
                <span class="error_form" v-if="showMessage"> Ingresa un rut valido </span>
              </div>
              <br />  
              <div class=rut-form>
                <h4 class=rut-text> Ingresa tu Clave Unica </h4>
                <input type="password" class="form-control password" v-model="password">
                <div class="input-info">
                  <small>No estamos guardando tu información </small>
                  <br/>
                  <small>Tu información personal es privada y está protegida, nadie podrá verlo. </small>
                  <br/>
                  <small>En caso de dudas contactate con ventas@wift.cl</small>
                </div>
              </div>
              <br />
              <div class=rut-form>
                <div class="form-group" id="form-checkbox-field" style="display: block">
                  <input id="checkbox" name="checkbox" type="checkbox"  onclick="" v-model="selected"/>
                  <small class="checkbox-label"> Acepto la <a :href="`${publicPath}Política_de_Privacidad-Wift.pdf`"  target="_blank" style="text-decoration: underline;" type="application/pdf">política de privacidad</a>, por ende, brindar&eacute; acceso a Wift a su informaci&oacute;n, para que construya mi perfil de informaci&oacute;n financiera. </small>
                </div>
              </div>
              <div>
                <v-btn class="submit-button" @click="submitFloid()" :disabled="!ready" :loading="loading">
                  Continuar
                </v-btn>
                <!-- <v-btn class="submit-button" @click="nextStep()" :loading="loading">
                  Continuar
                </v-btn> -->
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2" v-if="workerType == 1">
            <h3 style="margin-left:1.5rem; text-align: left; margin-bottom:1rem">Hola {{name}}! Solo faltan algunos datos...</h3>
            <div class=form-box> 
              <div class=rut-form>
                <h4 class=rut-text> Selecciona tu banco </h4>
                  <v-select
                    height="1rem"
                    style="font-size:12px; text-align:left;"
                    :items="banks"
                    v-model="selectedBank"
                    dense
                    outlined
                  ></v-select>
              </div> 
              <div class=rut-form>
                <h4 class=rut-text> Ingresa tu contraseña de internet </h4>
                <input type="password" class="form-control password" v-model="password">
                <div class="input-info">
                  <small>No estamos guardando tu información </small>
                  <br/>
                  <small>Tu información personal es privada y está protegida, nadie podrá verlo. </small>
                  <br/>
                  <small>En caso de dudas contactate con ventas@wift.cl</small>
                </div>
              </div>
              <br />
              <div class=rut-form>
                <div class="form-group" id="form-checkbox-field" style="display: block">
                  <input id="checkbox" name="checkbox" type="checkbox"  onclick="" v-model="selected"/>
                  <small class="checkbox-label"> Acepto la <a :href="`${publicPath}Política_de_Privacidad-Wift.pdf`"  target="_blank" style="text-decoration: underline;" type="application/pdf">política de privacidad</a>, por ende, brindar&eacute; acceso a Wift a su informaci&oacute;n, para que construya mi perfil de informaci&oacute;n financiera. </small>
                </div>
              </div>
              <div>
                <v-btn class="submit-button" @click="submitFloidInd()" :disabled="!ready" :loading="loading">
                  Continuar
                </v-btn>
                <!-- <v-btn class="submit-button" @click="nextStep()" :loading="loading">
                  Continuar
                </v-btn> -->
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="max-width: 400px; color: #003242">
              <h1 style="font-family: 'Ubuntu';margin-top: 1rem">
                Gracias por compartir tu información!
              </h1>
              <h3 style="font-family: 'Ubuntu'; margin-top: 1rem">
                En las próximas 12 horas Wift te contactará para avanzar con el proceso de contratación.
              </h3>
                <img
                :src="require('../assets/check3.png')"
                width="50%"
                color="blue"
                style="margin-top:4rem"
                />
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
  import LoadingCs from '../components/LoadingCs.vue'
  import TyC from '../components/TyC.vue'
    export default {
        name:"FloidView",
        data(){
          return{
            publicPath: process.env.BASE_URL,
            rut:"",
            email:"",
            password:"",
            selected: false, 
            loading:false,
            e1: 1,
            workerType: 0,
            absolute: true,
            opacity: 1,
            overlay: false,
            banks:["BANCO SANTANDER", "BANCO BCI", "BANCO FALABELLA", "BANCO ITAU", "BANCO DE CHILE / EDWARDS CITI", "BANCO ESTADO", "BANCO BICE", "BANCO SECURITY", "SCOTIABANK"],
            selectedBank:"BANCO SANTANDER",
            loading_step1: false
          }
        },
        components:{
          LoadingCs,
          TyC
        },
        computed:{
          name(){
            return this.$store.getters.getName
          },
          ready(){
            return this.validRut && this.selected && this.passwordValidator
          },
          ready1(){
            return this.email != "" && this.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
          },
          readyStep1(){
            return this.validRut && this.workerType != null
          },
          showMessage(){
            var valor = this.clean(this.rut);
            var cuerpo = valor.slice(0, -1);
            if (cuerpo.length<3)
              return false
            return !this.validRut 
          },
          passwordValidator(){
            return this.password.length > 0 //&& /\d/.test(this.password) &&
          },
          validRut(){
            // Obtiene el valor ingresado quitando puntos y guión.
            var valor = this.clean(this.rut);
            // Divide el valor ingresado en dígito verificador y resto del RUT.
            var cuerpo = valor.slice(0, -1);
            var dv = valor.slice(-1).toUpperCase();
            // Si no cumple con el mínimo ej. (n.nnn.nnn)
            if (cuerpo.length < 7) {
              return false;
            }

            // Calcular Dígito Verificador "Método del Módulo 11"
            var suma = 0;
            var multiplo = 2;

            // Para cada dígito del Cuerpo
            for (var i = 1; i <= cuerpo.length; i++) {
              // Obtener su Producto con el Múltiplo Correspondiente
              var index = multiplo * valor.charAt(cuerpo.length - i);

              // Sumar al Contador General
              suma = suma + index;

              // Consolidar Múltiplo dentro del rango [2,7]
              if (multiplo < 7) {
                multiplo = multiplo + 1;
              } else {
                multiplo = 2;
              }
            }

            // Calcular Dígito Verificador en base al Módulo 11
            var dvEsperado = 11 - (suma % 11);

            // Casos Especiales (0 y K)
            dv = dv == "K" ? 10 : dv;
            dv = dv == 0 ? 11 : dv;

            // Validar que el Cuerpo coincide con su Dígito Verificador
            if (dvEsperado != dv) {
              return false;
            } else {
              return true;
            }
          }
        
        },
        methods:{
          closeOverlay(){
            this.overlay = false
          },
          nextStep(){
            if (this.workerType == 0) this.e1 += 1
            else {
              this.loading_step1 = true
              const data = {"rut": this.clean(this.rut)}
              this.$store.dispatch('csRegistroCivilIndependent', data)
              .then(()=> {
                setTimeout(()=>{
                  if (this.$store.getters.getName){
                    this.e1 += 1
                  }
                  this.loading_step1 = false
                }, 100)
              })
            }
          },
          
          format (rut) {
            rut = this.clean(rut)

            var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
            for (var i = 4; i < rut.length; i += 3) {
              result = rut.slice(-3 - i, -i) + '.' + result
            }
            return result
          },
          clean (rut) {
            return typeof rut === 'string'
              ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
              : ''
          },
          checkRut(ev) {
            console.log(ev)
            console.log("HOLAAAAAA")

            var rut = this.rut


            // Separa con un Guión el cuerpo del dígito verificador.
            rut = this.format(rut);
            console.log(rut)
            this.rut = rut
          },
          blockSpecialChar(evt) {
            var charCode = evt.which ? evt.which : evt.keyCode;
            console.log(charCode)
            if (charCode > 31 && (charCode < 48 || charCode > 57) &&  charCode !== 75 &&  charCode !== 107) {
              console.log(false)
              return false
              }
            console.log(true)
            return true;
          },
          submitFloidInd(){
            this.loading = true
            const data = {rut: this.clean(this.rut), bank: this.selectedBank, password: this.password}
            this.$store.dispatch('csBankProducts', data)
            .then(()=> {
              setTimeout(()=> {
                if (this.$store.getters.getAccount != ''){
                  data["account_number"] = this.$store.getters.getAccount
                  this.$store.dispatch('csBankIncomev2', data)
                  .then(() => {
                    setTimeout(()=>{
                      if(this.$store.getters.getIncomev2) {
                        this.e1 += 1 
                        console.log("ENTRE incomeV2 CORRECTO")
                      }
                    this.loading = false
                    }, 100)
                  })
                }
                else this.loading = false
              }, 100)
            })
            console.log(data)

          },
          submitFloid(){
            this.loading = true
            const data = {mail: this.email, rut: this.clean(this.rut), password: this.password }
            this.$store.dispatch('csAfc', data)
            .then(() => {
              setTimeout(()=>{
                if (this.$store.getters.getAFC) {
                  console.log("ENTRE AFC CORRECTO")
                  this.$store.dispatch('csDeuda', data)
                  .then(() => {
                    setTimeout(() => {
                    if (this.$store.getters.getDeuda) {
                      console.log("ENTRE DEUDA CORRECTO")
                        this.$store.dispatch('csSii', data)
                        .then(()=>{
                          setTimeout(()=>{
                            if (this.$store.getters.getSII) {
                              console.log("ENTRE SII CORRECTO")
                              this.$store.dispatch('csRegistroCivil', data)
                              .then(()=>{
                                setTimeout(()=>{
                                  
                                  if(this.$store.getters.getRegistroCivil) {
                                    this.e1 += 1
                                    console.log("ENTRE Registro Civil CORRECTO")
                                    }
                                  this.loading = false
                                }, 100)
                              }) 
                            }
                            else this.loading = false
                          }, 100)
                        })
                      }
                      else this.loading = false
                    }, 100)
                  })
                }
                else this.loading = false
              }, 100)
            })
          }
        }
      }
</script>

<style lang="scss" scoped>
  .error_form{
    color: red;
    font-size: 12px;
  }
  .stepper{
    box-shadow: none !important;
    max-width: 350px;
    color:#003242 !important;
    .v-overlay{
      align-items: flex-start;
      width: 100%;
      display: block;
    }
  }
  .v-stepper__content{
    padding: 0px;
  }
  .v-stepper__header{
    box-shadow: none !important;
  }
  .v-application .primary {
    background-color: #003242 !important;
  }
  .overlay{
    background-color: #FFFFFF;
    .v-overlay__scrim{
      background-color: #FFFFFF !important;
    }
  }
  .v-overlay__scrim{
      background-color: #FFFFFF !important;
    }
  .form-box{
    max-width: 330px;
  }
  .v-label{
    font-size: 15px;
    font-weight: 600;
  }
  .color-gob{
    position: relative; 
    top:30;
    left:0;
    text-align: -webkit-center;
  }
  .rut-form{
    font-family: Montserrat-Light;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    .rut-text{
      color:#003242;
    }
    .form-group{
      color:#003242;
      font-family: Montserrat;
      font-size: 15px;
    }
    .form-control{
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      margin-top: 0.5rem;
    }
    .password{
      font: small-caption !important;
      font-size: 18px !important;
    }
    .input-info{
      text-align: end;
      font-size: 95%;
    }
  }
  .submit-button{
      background-color: #4F7181 !important;
      color: #FFFFFF !important;
      font-size: 20px;
      font-family: Ubuntu;
      margin-top: 2rem;
      border-radius: 28px;
      height: 55px !important;
      width: 250px !important;
      font-weight: bold;
  }
  

</style>