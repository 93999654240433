<template>
<div style="text-align: -webkit-center;">
  <!-- <TopBar @openDialog="openDialog"/> -->
  <NotificationContainer/>
  <v-app style="width:100%; margin-top:0.5rem;">
    <router-view/>
    <LoginDialog v-if="showLogInDialog" @closeDialog="closeDialog"/>
    <RegisterDialog v-if="showRegisterDialog" @closeDialog="closeDialog"/>
  </v-app>

</div>
</template>

<script>
  // <HelloWorld @openDialog="openDialog"/>
import { EventBus } from './utils'
import LoginDialog from './components/LoginDialog.vue'
import RegisterDialog from './components/RegisterDialog.vue'
import NotificationContainer from '@/components/NotificationContainer.vue'
// import HelloWorld from './components/HelloWorld';
// import TopBar from './components/TopBar'

export default {
  name: 'App',

  components: {
    // TopBar,
    // HelloWorld,
    LoginDialog,
    RegisterDialog,
    NotificationContainer
  },
  data(){
    return {
        loading: true,
        logged: false,
        showRegisterDialog: false,
        showLogInDialog: false,
        cuota: 0
    }
  },
  afterCreate(){
    this.$store.commit('setJwtLocalStorage')
  },
  created(){
    this.$vuetify.theme.themes.light.primary = '#4F7181 !important'
    this.$vuetify.theme.light = true
    console.log(this.$route.query.cuota)
    console.log(this.$route.query)
    if (this.$route.query.cuota) this.cuota = parseInt(this.$route.query.cuota)
  },
  methods:{
    closeDialog(dialog){
      console.log(dialog,'close')
      if (dialog == 'login') this.showLogInDialog = false
      else if (dialog == 'register') this.showRegisterDialog = false
    },
    openDialog(dialog){
      console.log(dialog,'open')
      if (dialog == 'login') this.showLogInDialog = true
      else if (dialog == 'register') this.showRegisterDialog = true
    }
  },
  mounted(){
    EventBus.$on('openDialog', (dialog) => {
      this.openDialog(dialog)
      })
    EventBus.$on('closeDialog', (dialog) => {
      this.closeDialog(dialog)
      })
  },
  beforeDestroy () {
    EventBus.$off('openDialog')
    EventBus.$off('closeDialog')
  },
};
</script>
<style>
.v-application--wrap{
  min-height: min-content !important;
}
.menuable__content__active{
  text-align: left;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
   url(./fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url(./fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat-Thin"),
   url(./fonts/Montserrat/static/Montserrat-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  src: local("Montserrat-ExtraLight"),
   url(./fonts/Montserrat/static/Montserrat-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
   url(./fonts/Montserrat/static/Montserrat-Light.ttf) format("truetype");
}
</style>
