<template>
  <v-row justify="center">
  
    <v-dialog
      v-model="show"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Entrar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  v-model="email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password*"
                  type="password"
                  v-model="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            @click="authenticate"
          >
            Log In
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { EventBus } from '@/utils'
import router from '../router'
 export default {
   data(){
    return {
        show: true,
        password:"",
        email:"",
        loading: false
    }
   },
   watch:{
      show:function(newValue){
      if(!newValue){
        this.closeDialog()
      }
    },
    },

   mounted () {
    EventBus.$on('failedRegistering', (msg) => {
      this.errorMsg = msg
      })
    EventBus.$on('failedAuthentication', (msg) => {
    this.errorMsg = msg
      })
    },

    methods: {
      closeDialog(){
          this.$emit('closeDialog', 'login')
      },
      register () {
        this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
      },
      authenticate () {
        this.loading = true
        this.$store.dispatch('login', { email: this.email, password: this.password })
        .then(() => {
          if (this.$store.getters.isAuthenticated){
            this.closeDialog()
            router.push({ name: 'FloidView' })
          }
          this.loading = false
        })
      },
    },
    
    beforeDestroy () {
      EventBus.$off('failedRegistering')
      EventBus.$off('failedAuthentication')
    }
 }
</script>
