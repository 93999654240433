import axios from 'axios'
// const API_URL = 'https://wift-payments.herokuapp.com'
// const API_URL = 'https://devapigw.infocheck.cl/infocheck/partner/'
//const WIFT_URL = 'https://wift.credit/'
const WIFT_URL = 'https://wift.credit/'
// const WIFT_URL = 'http://127.0.0.1:5000/'

export function documentValidation (data) {
    return axios.post(WIFT_URL + 'cedula_validation', data)
  }

export function claveUnicaValidation (data) {
    return axios.post(WIFT_URL + 'clave_unica_validation', data)
  }

export function creditScoringMetrik (data) {
    return axios.post(WIFT_URL + 'credit-scoring-metriks-v2', data)
  }