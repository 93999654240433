import Vue from "vue";
import VueRouter from "vue-router";
import HelloWorld from "../components/HelloWorld.vue";
import FloidView from "../views/FloidView.vue";
import MetriksView from "../views/MetriksView.vue";
import HelloWorldSe from "../components/HelloWorldSe.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HelloWorld",
    component: HelloWorld
  },
  {
    path: "/se",
    name: "HelloWorld",
    component: HelloWorldSe
  },
  {
    path: "/credit-scoring",
    name: "FloidView",
    component: FloidView
  },
  {
    path: "/credit-scoring-se",
    name: "MetriksView",
    component: MetriksView
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;