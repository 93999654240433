import { render, staticRenderFns } from "./MetriksView.vue?vue&type=template&id=4c1b74fd&scoped=true&"
import script from "./MetriksView.vue?vue&type=script&lang=js&"
export * from "./MetriksView.vue?vue&type=script&lang=js&"
import style0 from "./MetriksView.vue?vue&type=style&index=0&id=4c1b74fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1b74fd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VDivider,VOverlay,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep})
