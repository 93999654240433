<template>
  <div class="notification-bar"  :class="notificationTypeClass">
    <v-alert
      dark
      type="error"
      shaped
      v-on:click="removeNotification()"
    >
      <p class="error-message">{{ notification.message }} </p>
    </v-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null
    }
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 5000)
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  computed: {
    notificationTypeClass() {
      return `-text-${this.notification.type}`
    }
  },
  methods: {
      ...mapActions('notification', ['remove']),
      removeNotification(){
          console.log("remove")
          this.remove(this.notification)
      }
  }
}
</script>

<style scoped>
    .notification-bar {
        margin: 1em 0 1em;
        font-family: Ubuntu;
    }
    .v-alert{
        background-color: #f44336 !important; 
    }
    .error-message{
        font-size: 12px;
        font-weight: 900;
    }
</style>
